import { AddIcon } from './components'
import { styles } from './styles'

interface AddTitleProps {
  text: string
  onClick: () => void
  dataTestId?: string
}

export function AddTitle({ onClick, text, dataTestId }: AddTitleProps): JSX.Element {
  return (
    <div css={styles.container} onClick={onClick} data-testid={dataTestId}>
      <AddIcon />
      <span>{text}</span>
    </div>
  )
}
