import { useTheme } from '@emotion/react'

export function ShoppingCartIcon(): JSX.Element {
  const theme = useTheme()

  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66669 11C3.93335 11 3.34002 11.6 3.34002 12.3333C3.34002 13.0667 3.93335 13.6667 4.66669 13.6667C5.40002 13.6667 6.00002 13.0667 6.00002 12.3333C6.00002 11.6 5.40002 11 4.66669 11ZM0.666687 1.00001C0.666687 1.36668 0.966687 1.66668 1.33335 1.66668H2.00002L4.40002 6.72668L3.50002 8.35334C3.01335 9.24668 3.65335 10.3333 4.66669 10.3333H12C12.3667 10.3333 12.6667 10.0333 12.6667 9.66668C12.6667 9.30001 12.3667 9.00001 12 9.00001H4.66669L5.40002 7.66668H10.3667C10.8667 7.66668 11.3067 7.39334 11.5334 6.98001L13.92 2.65334C13.9762 2.55229 14.005 2.43832 14.0036 2.32271C14.0023 2.2071 13.9707 2.09386 13.9121 1.99419C13.8535 1.89451 13.7699 1.81186 13.6696 1.75442C13.5692 1.69698 13.4556 1.66673 13.34 1.66668H3.47335L3.02669 0.713344C2.97311 0.599548 2.88822 0.503371 2.78196 0.436073C2.6757 0.368775 2.55247 0.333142 2.42669 0.333344H1.33335C0.966687 0.333344 0.666687 0.633344 0.666687 1.00001V1.00001ZM11.3334 11C10.6 11 10.0067 11.6 10.0067 12.3333C10.0067 13.0667 10.6 13.6667 11.3334 13.6667C12.0667 13.6667 12.6667 13.0667 12.6667 12.3333C12.6667 11.6 12.0667 11 11.3334 11Z"
        fill={theme.SUMMARY_BUTTON_TEXT_COLOR}
      />
    </svg>
  )
}
