type EffectFinallyResponse =
  | {
      status: 'done'
      params: any
      result: any
    }
  | {
      status: 'fail'
      params: any
      error: Error
    }

export const updateError = (f: EffectFinallyResponse): string =>
  f.status === 'fail' ? f.error.message : ''
