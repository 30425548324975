import { Iframe } from '../../features/add-payment-info/types'
import { styles } from './styles'

interface TokenexInputProps {
  iframe: Iframe
  icon?: JSX.Element

  label: string
  name?: string
  containerId: string

  onLabelClick: () => void
}

export function TokenexInput({
  iframe,
  icon,

  label,
  containerId,
  name,

  onLabelClick,
}: TokenexInputProps): JSX.Element {
  return (
    <div css={[styles.container.base, icon && styles.container.withIcon]}>
      {icon}
      <div
        id={containerId}
        css={styles.iframeContainer}
        data-testid={`${name}_iframe_container`}
      />

      <label
        css={[
          styles.label.base,
          iframe.focused && styles.label.top,
          iframe.error && styles.label.error,
        ]}
        onClick={onLabelClick}
      >
        {label}
      </label>

      {iframe.error && (
        <div css={styles.error} id={`${name}_input-error`} data-testid="input-error">
          {iframe.error.charAt(0).toUpperCase() + iframe.error.slice(1)}
        </div>
      )}
    </div>
  )
}
