// TODO: move add-discount-code to separate feature

import { Payment, Checkout, CompleteCheckoutRequest } from '@softcery/qc-apiclient'
import {
  createStore,
  combine,
  createEffect,
  restore,
  createEvent,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { apiService, errorHandler } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { updateError } from '~/shared/lib'

const paymentInitialState = {
  ccName: '',
  ccExpMonth: '',
  ccExpYear: '',
  ccFirstSix: '',
  ccLastFour: '',
  ccType: '',
}

export const updateDiscountFx = createEffect(
  async (payload: {
    discountCode: string
    vendorId: string
    checkout: Checkout
    setFieldError: (message: string) => void
  }) => {
    try {
      payload.setFieldError('')
      return await apiService.updateDiscount({
        vendorId: payload.vendorId,
        fields: {
          checkout: {
            ...payload.checkout,
            payment: {
              discountCode: payload.discountCode,
            },
          },
        },
      })
    } catch (res) {
      return errorHandler(res, '', payload.setFieldError)
    }
  },
)

export const deleteDiscountFx = createEffect(
  async (payload: {
    vendorId: string
    checkout: Checkout
    setFieldValue: (value: string) => void
    setFieldError: (message: string) => void
  }) => {
    try {
      const response = await apiService.deleteDiscount({
        vendorId: payload.vendorId,
        fields: {
          checkout: payload.checkout,
        },
      })
      payload.setFieldValue('')
      return response
    } catch (res) {
      return errorHandler(res, 'Failed to delete discount', payload.setFieldError)
    }
  },
)

export const completeCheckoutFx = createEffect(
  async (payload: CompleteCheckoutRequest) => {
    let response
    try {
      response = await apiService.completeCheckout(payload)
      if (response.orderStatusUrl) {
        // save order status page url
        sessionModel.setOrderStatusUrl(response.orderStatusUrl)
      }
      return response
    } catch (res) {
      return errorHandler(res, `Failed to complete checkout`)
    }
  },
)

export const resetState = createEvent()
export const switchFormSubmitting = createEvent<boolean>()

export const $completeCheckoutStatus = combine({
  loading: completeCheckoutFx.pending,
  error: restore(completeCheckoutFx.finally.map(updateError), '').reset(
    completeCheckoutFx,
  ),
})

export const $formSubmitting = createStore(false)

export const $couponUpdating = createStore(false)
export const $couponApplied = createStore(false)

export const $payment = createStore<Payment>(paymentInitialState)
export const $acceptsMarketingNewsletter = createStore(false)
export const $discountCode = createStore('')

$formSubmitting.on(switchFormSubmitting, (_, payload) => payload)
// TODO: check working
$formSubmitting.on(completeCheckoutFx.pending, (_, payload) => payload)

$couponUpdating.on(
  [updateDiscountFx.pending, deleteDiscountFx.pending],
  (_, pending) => pending,
)

$couponApplied
  .on(updateDiscountFx.done, () => true)
  .on(deleteDiscountFx.done, () => false)
  .reset(resetState)

$payment
  .on(customerModel.getCustomerFx.doneData, (state, { customer }) => ({
    ...state,
    ccName: customer?.ccName,
    ccExpMonth: customer?.ccExpMonth,
    ccExpYear: customer?.ccExpYear,
    ccFirstSix: customer?.ccFirstSix,
    ccLastFour: customer?.ccLastFour,
    ccType: customer?.ccType,
  }))
  .on(completeCheckoutFx.done, (state, { params }) => ({
    ...state,
    tokenexToken: params.fields.tokenexToken || '',
    tokenexTokenHMAC: params.fields.tokenexTokenHMAC || '',
  }))
  .reset(resetState)

$acceptsMarketingNewsletter
  .on(
    sessionModel.createCheckoutFx.doneData,
    (_, { checkoutSettings }) =>
      checkoutSettings?.marketingCheckboxVisibility === 'show' &&
      checkoutSettings?.marketingCheckboxDefaultState === 'checked',
  )
  .reset(resetState)

$discountCode
  .on(
    [updateDiscountFx.doneData, deleteDiscountFx.doneData],
    (_, { checkout }) => checkout?.payment?.discountCode,
  )
  .reset(resetState)

// sync with local storage
persist({ store: $payment, key: LOCAL_STORAGE_KEYS.Payment })
persist({ store: $discountCode, key: LOCAL_STORAGE_KEYS.DiscountCode })
persist({
  store: $acceptsMarketingNewsletter,
  key: LOCAL_STORAGE_KEYS.AcceptsMarketingNewsletter,
})

// TODO: move this code to other place
sample({
  clock: [updateDiscountFx.doneData, deleteDiscountFx.doneData],
  fn: ({ checkout }) => checkout!,
  target: sessionModel.setCheckout,
})
