import { ReactNode } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CheckmarkIcon } from '..'
import { styles } from './styles'

interface StepProps {
  linkTo: string
  completed: boolean
  accessible: boolean // whether can be navigated to
  id?: string

  children?: ReactNode
}

export function Step({
  linkTo,
  completed,
  accessible,
  id,
  children,
}: StepProps): JSX.Element {
  const { pathname } = useLocation()

  return (
    <NavLink
      data-testid={id}
      to={linkTo}
      exact
      css={[styles.container, accessible && styles.accessible]}
      onClick={(e) => {
        if (!accessible) {
          e.preventDefault()
        }
      }}
    >
      <span
        id={id}
        css={[
          styles.span.base,
          pathname.split('?')[0] === linkTo.split('?')[0] && styles.span.active,
          accessible && styles.accessible,
        ]}
      >
        {children}
      </span>
      <CheckmarkIcon isVisible={completed} />
    </NavLink>
  )
}
