import querystring from 'querystring'
import { useTheme } from '@emotion/react'
import { Payment } from '@softcery/qc-apiclient'
import { useStore } from 'effector-react'
import { Formik, FormikProps, FormikValues } from 'formik'
import mixpanel from 'mixpanel-browser'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { addPaymentInfoModel, Form } from '~/features/add-payment-info'
import { saveDetailsModel } from '~/features/save-details'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import {
  americanExpressIcon,
  cardPlaceholderIcon,
  discoverIcon,
  mastercardIcon,
  visaIcon,
} from '~/shared/assets/icons'
import { urlCreator } from '~/shared/lib'
import {
  getFacebookAnalytics,
  getGoogleAnalyticsV3,
  getGoogleAnalyticsV4,
} from '~/shared/lib/analytics'

import {
  TokenexIframes,
  TokenexValidateEventListenerData,
  TokenexTokenizeEventListenerData,
} from '../../features/add-payment-info/types'
import { getTokenexIframeStyles } from './styles'
import { validationSchema } from './validation'

declare global {
  interface Window {
    TokenEx: any
  }
}

export function PaymentScreen(): JSX.Element {
  const history = useHistory()
  const theme = useTheme()

  // vendor id
  const vendorId = useStore(sessionModel.$vendorId)

  // checkout state
  const config = useStore(sessionModel.$config)

  // customer state
  const customer = useStore(customerModel.$customer)

  // delivery state
  const delivery = useStore(addDeliveryInfoModel.$delivery)

  // payment state
  const payment = useStore(addPaymentInfoModel.$payment)

  const successfullyGetInformation = useStore(customerModel.$successfullyGetInformation)

  const acceptsSavingDetails = useStore(saveDetailsModel.$acceptsSavingDetails)
  const successfullySavedInformation = useStore(
    saveDetailsModel.$successfullySavedInformation,
  )

  // state for card icon
  const [cardType, setCardType] = useState<string>(payment?.ccType || '')
  const cardIcon: string = useMemo(() => {
    switch (cardType) {
      case 'visa':
        return visaIcon
      case 'masterCard':
        return mastercardIcon
      case 'americanExpress':
        return americanExpressIcon
      case 'discover':
        return discoverIcon
      default:
        return cardPlaceholderIcon
    }
  }, [cardType])

  // formik state need for validation and tokenization
  const [formikData, setFormikData] = useState<Payment>(payment || {})
  const [savedPaymentDetails, setSavedPaymentDetails] = useState<boolean>(
    customer.tokenexToken !== '',
  )

  // state for tokenex iframes
  const [iframes, setIframes] = useState<TokenexIframes>({
    ccNumber: {
      focused: false,
      error: '',
    },
    ccCvv: {
      focused: false,
      error: '',
    },
    isValid: false,
  })

  // refs for validation and tokenization
  const tokenexIframeRef = useRef<any>(null)
  const isFormikValidRef = useRef<boolean>(false)
  const isTokenexValidRef = useRef<boolean>(false)

  // update ref when iframes isValid state changed
  useEffect(() => {
    isTokenexValidRef.current = iframes.isValid
  }, [iframes.isValid])

  // track event "Payment section opened"
  useEffect(() => {
    mixpanel.track('Payment section opened', {
      storeDomain: vendorId,
      productIDs: config?.checkout?.products?.map((product) => product.productId),
    })
  }, [])

  // add tokenex iframe
  useEffect(() => {
    // create tokenex iframe configuration object
    const iframeConfig = {
      tokenExID: config?.tokenexId,
      tokenScheme: 'PCI',
      authenticationKey: config?.tokenexAuthKey,
      timestamp: config?.tokenexTimestamp,
      origin: `${window.location.origin},${
        querystring.parse(window.location.search.slice(1)).origin
      }`,
      pci: true,
      cvv: true,
      cvvContainerID: 'tokenexIframeCCCvvContainer',
      placeholder: 'Card Number',
      cvvPlaceholder: 'CVV',
      font: 'Montserrat',
      enablePrettyFormat: true,
      styles: {
        ...getTokenexIframeStyles(theme),
      },
    }

    // initialize iframe
    tokenexIframeRef.current = window.TokenEx?.Iframe(
      'tokenexIframeCCNumberContainer',
      iframeConfig,
    )

    // add event listeners
    tokenexIframeRef?.current?.on('load', () => console.debug('TokenEx iFrame Loaded'))
    tokenexIframeRef?.current?.on('focus', () =>
      setIframes((prev) => ({ ...prev, ccNumber: { ...prev.ccNumber, focused: true } })),
    )
    tokenexIframeRef?.current?.on(
      'cardTypeChange',
      (data: { possibleCardType: string }) => setCardType(data.possibleCardType),
    )
    tokenexIframeRef?.current?.on('cvvFocus', () =>
      setIframes((prev) => ({ ...prev, ccCvv: { ...prev.ccCvv, focused: true } })),
    )
    tokenexIframeRef?.current?.on(
      'validate',
      ({
        cvvValidator,
        isCvvValid,
        isValid,
        validator,
      }: TokenexValidateEventListenerData) => {
        console.debug('TokenEx iFrame validation')

        setIframes((prev) => ({
          ccNumber: {
            ...prev.ccNumber,
            error: validator || '',
          },
          ccCvv: {
            ...prev.ccCvv,
            error: cvvValidator || '',
          },
          isValid: isValid && isCvvValid,
        }))
      },
    )

    // load iframe to DOM
    tokenexIframeRef?.current?.load()
  }, [])

  const completeCheckout = ({
    firstSix,
    lastFour,
    cardType,
    token,
    tokenHMAC,
  }: Partial<TokenexTokenizeEventListenerData>) => {
    const payment = {
      ...formikData,
      ccFirstSix: firstSix,
      ccLastFour: lastFour,
      ccType: cardType,
    }

    addPaymentInfoModel
      .completeCheckoutFx({
        vendorId,
        fields: {
          checkout: {
            ...config?.checkout,
            payment,
          },
          tokenexToken: token,
          tokenexTokenHMAC: tokenHMAC,
        },
      })
      // TODO: move to effector model
      .then(() => {
        history.push(
          urlCreator(
            acceptsSavingDetails &&
              !successfullyGetInformation &&
              !successfullySavedInformation
              ? '/save-details'
              : '/finish',
          ),
        )

        if (config?.checkout) {
          window.parent?.postMessage(JSON.stringify({ type: 'purchase' }), '*')

          // track event "Purchase"
          getGoogleAnalyticsV3()?.purchase(config?.checkout)
          getGoogleAnalyticsV4()?.purchase(config?.checkout)
          getFacebookAnalytics()?.purchase(config?.checkout)

          // track event "Payment section completed"
          mixpanel.track('Payment section completed')
        }
      })

    if (config?.checkout) {
      getGoogleAnalyticsV3()?.addPaymentInfo(config?.checkout)
      getGoogleAnalyticsV4()?.addPaymentInfo(config?.checkout)
      getFacebookAnalytics()?.addPaymentInfo(config?.checkout)
    }
  }

  // add tokenize event listener
  tokenexIframeRef?.current?.on(
    'tokenize',
    (fields: TokenexTokenizeEventListenerData) => {
      console.debug('TokenEx iFrame tokenization')
      completeCheckout({ ...fields })
    },
  )

  // submit form
  const onSubmit = (data: Payment) => {
    // start loading, button disabled
    addPaymentInfoModel.switchFormSubmitting(true)

    // set formik data to state
    setFormikData(data)

    // check validation and if it succeed - tokenize
    const timeoutId = setTimeout(() => {
      // check validation, tokenize if not saved information
      if (isTokenexValidRef.current && isFormikValidRef.current && !savedPaymentDetails) {
        tokenexIframeRef?.current?.tokenize()
      } else if (savedPaymentDetails) {
        // complete checkout with saved information
        completeCheckout({
          firstSix: payment?.ccFirstSix,
          lastFour: payment?.ccLastFour,
          cardType: payment?.ccType,
          token: customer.tokenexToken,
          tokenHMAC: customer.tokenexTokenHMAC,
        })
      } else {
        addPaymentInfoModel.switchFormSubmitting(false)
      }

      // clear timeout id
      clearTimeout(timeoutId)
    }, 1000)
  }

  return (
    <Formik
      initialValues={{
        // use first name and last name from delivery if empty
        ccName: payment?.ccName || `${delivery?.firstName} ${delivery?.lastName}`,
        ccExpMonth: payment?.ccExpMonth,
        ccExpYear: payment?.ccExpYear,
        acceptsMarketingNewsletter: payment?.acceptsMarketingNewsletter,
        discountCode: payment?.discountCode,
      }}
      // if saved information do not validate
      validationSchema={savedPaymentDetails ? null : validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form
          {...props}
          isSavedPaymentDetails={savedPaymentDetails}
          setSavedPaymentDetails={setSavedPaymentDetails}
          cardIcon={cardIcon}
          cardType={cardType}
          resetCardType={() => setCardType('')}
          iframes={iframes}
          tokenexIframeRef={tokenexIframeRef}
          setIsFormikValidRef={(isValid) => {
            isFormikValidRef.current = isValid
          }}
        />
      )}
    </Formik>
  )
}
