import { useStore } from 'effector-react'
import mixpanel from 'mixpanel-browser'
import { Fragment, useEffect, useState } from 'react'

import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { addPaymentInfoModel } from '~/features/add-payment-info'
import { addShippingInfoModel } from '~/features/add-shipping-info'
import { saveDetailsModel } from '~/features/save-details'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { loaderIcon, phoneSmsIcon } from '~/shared/assets/icons'
import { CodeInput } from '~/shared/ui'

import { styles } from './styles'

export function SaveDetailsScreen(): JSX.Element {
  const vendorId = useStore(sessionModel.$vendorId)
  const orderStatusUrl = useStore(sessionModel.$orderStatusUrl)

  const delivery = useStore(addDeliveryInfoModel.$delivery)
  const shipping = useStore(addShippingInfoModel.$shipping)
  const payment = useStore(addPaymentInfoModel.$payment)

  const customer = useStore(customerModel.$customer)
  const customerExists = useStore(customerModel.$customerExists)

  const { loading: updatingCustomer, error: updateCustomerError } = useStore(
    customerModel.$updateCustomerStatus,
  )

  const successfullySavedInformation = useStore(
    saveDetailsModel.$successfullySavedInformation,
  )

  const [sixDigitCode, setSixDigitCode] = useState('')

  const [seconds, setSeconds] = useState(60)
  const [resentedCode, setResentedCode] = useState(false)

  useEffect(() => {
    if (successfullySavedInformation) {
      setTimeout(() => {
        window.parent?.postMessage(
          JSON.stringify({ type: 'navigate', href: orderStatusUrl }),
          '*',
        )
      }, 5000)
    }
  }, [successfullySavedInformation])

  useEffect(() => {
    if (resentedCode) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
      }, 1000)
      if (seconds === 0) {
        clearInterval(interval)
        setResentedCode(false)
        setSeconds(60)
      }
      return () => clearInterval(interval)
    }
    return undefined
  })

  // sends typed 6 digit code with customer info
  const sendCode = () =>
    customerModel
      .updateCustomerFx({
        code: sixDigitCode,
        shop: vendorId,
        phone: delivery.phone || '',
        fields: {
          delivery,
          selectedShippingRateId: shipping.selectedShippingRateId,
          ccName: payment.ccName,
          ccExpMonth: payment.ccExpMonth,
          ccExpYear: payment.ccExpYear,
          ccFirstSix: payment.ccFirstSix,
          ccLastFour: payment.ccLastFour,
          tokenexToken: customer.tokenexToken,
          tokenexTokenHMAC: customer.tokenexTokenHMAC,
        },
      })
      .then((payload) => {
        // TODO: replace to listener or sample
        customerModel.setCustomerCode(sixDigitCode)

        mixpanel.alias(payload.customer?.id || '')

        mixpanel.people.set({
          customerName: `${delivery?.firstName} ${delivery?.lastName}`,
          customerEmail: delivery?.email,
          customerPhone: delivery?.phone,
        })

        // if customer not exists track event "Registered"
        if (!customerExists) {
          mixpanel.track('Registered')
        }

        // track event "Save information completed"
        mixpanel.track('Saving information completed')
      })

  const resendSmsCode = () => {
    saveDetailsModel
      .createSmsCodeFx({
        phone: delivery.phone || '',
        shop: vendorId,
      })
      .then(() => {
        setResentedCode(true)
      })
  }

  useEffect(() => {
    if (sixDigitCode !== '') {
      sendCode()
    }
  }, [sixDigitCode])

  useEffect(() => {
    if (!successfullySavedInformation) {
      saveDetailsModel.createSmsCodeFx({
        phone: delivery.phone || '',
        shop: vendorId,
      })

      // track event "Begin save information"
      mixpanel.track('Saving information begin')
    }
  }, [])

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <div css={styles.imageContainer}>
          {updatingCustomer ? (
            <img css={styles.loader} src={loaderIcon} alt="loader" />
          ) : (
            <img src={phoneSmsIcon} alt="Phone sms" />
          )}
        </div>

        <div css={styles.title}>
          {successfullySavedInformation
            ? 'Your information was saved successfully'
            : 'Save your information'}{' '}
        </div>

        {successfullySavedInformation && (
          <div css={styles.infoTitle}>
            You will be redirected back to the store in 5 seconds...
          </div>
        )}

        {!successfullySavedInformation && (
          <Fragment>
            <div css={styles.infoTitle}>
              <span css={styles.enter}>Enter the code send to</span>{' '}
              <span css={styles.phone}>{delivery.phone}</span>
            </div>

            <CodeInput
              onCodeFill={(code) => setSixDigitCode(code)}
              isValid={updateCustomerError === ''}
            />

            {updateCustomerError && (
              <div css={styles.errorMessage}>{updateCustomerError}</div>
            )}

            <div css={styles.bottom.container}>
              <span css={styles.bottom.text}>
                It may take a minute to receive your code. <br /> Haven&apos;t received
                it?{' '}
              </span>

              <span>
                <span
                  css={[
                    styles.bottom.button.base,
                    resentedCode && styles.bottom.button.disabled,
                  ]}
                  onClick={() => {
                    if (!resentedCode) {
                      resendSmsCode()
                    }
                  }}
                >
                  Resend a new code.{' '}
                </span>

                {resentedCode && <span>({seconds})</span>}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}
