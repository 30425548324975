export const errorHandler = async (
  res: any,
  errorMessage: string,
  setFieldError?: (message: string) => void,
) => {
  if (res instanceof Response) {
    const data = await res.json()
    const fullErrorMessage = `${errorMessage}: ${data.message}`

    if (res.status === 500) {
      console.error(fullErrorMessage)
    } else {
      console.debug(fullErrorMessage)
    }

    setFieldError?.(fullErrorMessage)

    throw new Error(fullErrorMessage)
  }
  throw new Error('Unknown error')
}
