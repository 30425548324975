import { useTheme } from '@emotion/react'
import ReactCodeInput from 'react-code-input'
import { inputStyle } from './styles'

interface Props {
  onCodeFill: (code: string) => void
  isValid: boolean
  inputRef?: React.Ref<ReactCodeInput>
}

export function CodeInput({ onCodeFill, isValid, inputRef }: Props): JSX.Element {
  const theme = useTheme()

  return (
    <ReactCodeInput
      onChange={(code) => {
        if (code.length === 6) {
          onCodeFill(code)
        }
      }}
      inputMode="numeric"
      name="code"
      type="number"
      fields={6}
      inputStyle={inputStyle(isValid, theme.ERROR_1, theme.DARK_0)}
      ref={inputRef}
    />
  )
}
