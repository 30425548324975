import { createStore, createEvent, combine, restore, createEffect } from 'effector'
import { persist } from 'effector-storage/local'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { apiService, errorHandler } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { updateError } from '~/shared/lib'
import { CreateSmsCodePayload } from './types'

export const createSmsCodeFx = createEffect(
  async ({ phone, shop }: CreateSmsCodePayload) => {
    try {
      return await apiService.createSmsCode({
        fields: { phone, shop },
      })
    } catch (res) {
      return errorHandler(res, 'Failed to send sms code')
    }
  },
)

export const resetState = createEvent()
export const setAcceptsSavingDetails = createEvent<boolean>()

export const $createSmsCodeStatus = combine({
  loading: createSmsCodeFx.pending,
  error: restore(createSmsCodeFx.finally.map(updateError), ''),
})

export const $successfullySavedInformation = createStore(false)
export const $acceptsSavingDetails = createStore(false)

$acceptsSavingDetails
  .on(setAcceptsSavingDetails, (_, payload) => payload)
  .on(
    sessionModel.createCheckoutFx.doneData,
    (_, { checkoutSettings }) =>
      checkoutSettings?.savingDetailsEnableStatus === 'enabled' &&
      checkoutSettings?.savingDetailsCheckboxDefaultState === 'checked',
  )
  .reset(resetState)

$successfullySavedInformation
  .on(customerModel.updateCustomerFx.done, () => true)
  .reset(resetState)

// sync with local storage
persist({ store: $acceptsSavingDetails, key: LOCAL_STORAGE_KEYS.AcceptsSavingDetails })
persist({
  store: $successfullySavedInformation,
  key: LOCAL_STORAGE_KEYS.SuccessfullySavedInformation,
})
