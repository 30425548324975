import { ReactNode } from 'react'
import { styles } from './styles'

export interface ErrorProps {
  children?: ReactNode
}

export function Error({ children }: ErrorProps): JSX.Element {
  return <div css={styles.container}>{children}</div>
}
