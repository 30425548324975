import qs from 'querystring'
import InnerHTML from 'dangerously-set-html-content'
import { useStore } from 'effector-react'
import mixpanel from 'mixpanel-browser'
import { useEffect, Fragment } from 'react'

import { sessionModel } from '~/entities/session'
import { mixpanelProjectToken } from '~/shared/config'
import {
  getUrlParams,
  getFacebookAnalytics,
  getGoogleAnalyticsV3,
  getGoogleAnalyticsV4,
} from '~/shared/lib'

export const withAnalytics = (component: () => JSX.Element) => () => {
  const showCheckout = useStore(sessionModel.$showCheckout)
  const { vendorId, platform } = qs.parse(window.location.search.slice(1))

  const config = useStore(sessionModel.$config)

  useEffect(() => {
    if (!showCheckout) {
      return
    }

    if (config?.checkout) {
      getGoogleAnalyticsV3()?.beginCheckout(config?.checkout)
      getGoogleAnalyticsV4()?.beginCheckout(config?.checkout)
      getFacebookAnalytics()?.beginCheckout(config?.checkout)
    }
  }, [showCheckout])

  useEffect(() => {
    // initialize mixpanel analytics
    mixpanel.init(mixpanelProjectToken, { debug: false })

    mixpanel.register({
      storeDomain: vendorId,
      storePlatform: platform,
      numberOfProducts: config?.checkout?.products?.length,
      totalPrice: config?.checkout?.totalPrice,
      productIDs: config?.checkout?.products?.map((product) => product.productId),
    })

    if (showCheckout) {
      // track event "Checkout opened"
      mixpanel.track('Checkout opened')
    }
  }, [showCheckout])

  return (
    <Fragment>
      {config?.checkoutSettings?.googleAnalyticsV3Id !== '' && (
        <InnerHTML
          html={`
            <!-- Google Analytics -->
            <script>
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '${config?.checkoutSettings?.googleAnalyticsV3Id}', 'auto', {
              'clientId': '${getUrlParams('clientId')}',
              'cookieFlags': "SameSite=None; Secure",
              'allowLinker': true,
            });
            ga('require', 'ec');
            ga('send', 'pageview');
            </script>
            <!-- End Google Analytics -->
          `}
        />
      )}

      {config?.checkoutSettings?.googleAnalyticsMeasurementId !== '' && (
        <InnerHTML
          html={`
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=${config?.checkoutSettings?.googleAnalyticsMeasurementId}"
        ></script>
        <script>
          window.dataLayer = window.dataLayer || []
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag('js', new Date())
          gtag('config','${config?.checkoutSettings?.googleAnalyticsMeasurementId}', {
            cookie_flags: 'max-age=7200;secure;samesite=none',
            linker: {
              accept_incoming: true,
            }
          })
        </script>
      `}
        />
      )}

      {config?.checkoutSettings?.facebookPixelId !== '' && (
        <InnerHTML
          html={`
        <!-- Facebook Pixel Code -->
        <script>
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${config?.checkoutSettings?.facebookPixelId}');
          fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${config?.checkoutSettings?.facebookPixelId}&ev=PageView&noscript=1"
        /></noscript>
        <!-- End Facebook Pixel Code -->
      `}
        />
      )}

      {process.env.NODE_ENV === 'production' && (
        <InnerHTML
          html={`
          <!-- Hotjar Tracking Code for my site -->
          <script>
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2801539,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          </script>
      `}
        />
      )}

      {component()}
    </Fragment>
  )
}
