import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { config } from '~/shared/config'

export const initializeSentry = () => {
  // initialize monitoring if not local
  if (config.version !== 'local') {
    try {
      // start sentry
      Sentry.init({
        dsn: 'https://85dac68c08b44379871278aabd174ce8@o790881.ingest.sentry.io/5882985',
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({ levels: ['error'] }),
        ],

        tracesSampleRate: 1.0,
      })
      console.debug('successfully initialized sentry')
    } catch (error) {
      console.error('failed to initialize sentry', error)
    }
  }
}
