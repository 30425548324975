export const americanExpressIcon = require('./american-express.svg')
export const arrowIcon = require('./arrow.svg')
export const cardPlaceholderIcon = require('./card-placeholder.svg')
export const checkedIcon = require('./checked.svg')
export const closeIcon = require('./close.svg')
export const discoverIcon = require('./discover.svg')
export const editIcon = require('./edit.svg')
export const loaderIcon = require('./loader.svg')
export const lockLgIcon = require('./lock-lg.svg')
export const lockSmIcon = require('./lock-sm.svg')
export const mastercardIcon = require('./mastercard.svg')
export const okhandIcon = require('./okhand.svg')
export const phoneSmsIcon = require('./phone-sms.svg')
export const visaIcon = require('./visa.svg')
