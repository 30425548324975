import { useTheme } from '@emotion/react'
import { styles } from './styles'

interface CheckmarkIconProps {
  isVisible: boolean
}

export function CheckmarkIcon({ isVisible }: CheckmarkIconProps): JSX.Element {
  const theme = useTheme()

  return (
    <div css={[styles.container.base, isVisible && styles.container.visible]}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9L7.5 13.5L15 4.5"
          strokeWidth={2}
          stroke={theme.ACCENT_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
