import { Checkout } from '@softcery/qc-apiclient'
import {
  createStore,
  createEffect,
  combine,
  restore,
  createEvent,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import { addPaymentInfoModel } from '~/features/add-payment-info'
import { addShippingInfoModel } from '~/features/add-shipping-info'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { apiService, errorHandler } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { updateError } from '~/shared/lib'
import { Delivery } from '~/shared/types'

const initialState = {
  address1: '',
  address2: '',
  city: '',
  country: 'United States',
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '1',
  province: '',
  provinceCode: '',
  zip: '',
} as Delivery

export const updateDeliveryFx = createEffect(
  async (payload: { vendorId: string; checkout: Checkout }) => {
    try {
      return await apiService.updateDelivery({
        vendorId: payload.vendorId,
        fields: {
          checkout: payload.checkout,
        },
      })
    } catch (res) {
      return errorHandler(res, 'Failed to update delivery')
    }
  },
)

export const resetState = createEvent()

// TODO: remove it
export const setDelivery = createEvent<Partial<Delivery>>()

export const $updateDeliveryStatus = combine({
  loading: updateDeliveryFx.pending,
  error: restore(updateDeliveryFx.finally.map(updateError), ''),
})

// TODO: think about this loading
export const $commonLoading = combine(
  [
    sessionModel.createCheckoutFx.pending,
    addShippingInfoModel.updateShippingRateFx.pending,
    addPaymentInfoModel.updateDiscountFx.pending,
    addPaymentInfoModel.deleteDiscountFx.pending,
    updateDeliveryFx.pending,
  ],
  (p) => p.some(Boolean),
)

export const $delivery = createStore<Delivery>(initialState)

$delivery
  // TODO: remove setDelivery listener
  .on(setDelivery, (state, delivery) => ({ ...state, ...delivery }))
  .on(updateDeliveryFx.doneData, (state, { checkout }) => ({
    ...state,
    ...checkout?.delivery,
  }))
  .on(customerModel.getCustomerFx.doneData, (state, { customer }) => ({
    ...state,
    ...customer?.delivery,
    provinceCode: '',
  }))
  .reset(resetState)

// TODO: move these samples
sample({
  clock: updateDeliveryFx.doneData,
  fn: ({ checkout }) => checkout?.shippingRates || [],
  target: addShippingInfoModel.updateShippingRates,
})

sample({
  clock: updateDeliveryFx.doneData,
  fn: ({ checkout }) => checkout!,
  target: sessionModel.setCheckout,
})

// sync with local storage
persist({ store: $delivery, key: LOCAL_STORAGE_KEYS.Delivery })
