import { Route, Switch, Redirect } from 'react-router-dom'
import { urlCreator } from '~/shared/lib'
import { DeliveryScreen } from './delivery'
import { FinishScreen } from './finish'
import { PaymentScreen } from './payment'
import { SaveDetailsScreen } from './save-details'
import { ShippingScreen } from './shipping'

export function Routing(): JSX.Element {
  return (
    <Switch>
      <Route component={DeliveryScreen} path="/delivery" exact />

      <Route component={ShippingScreen} path="/shipping" exact />

      <Route component={PaymentScreen} path="/payment" exact />

      <Route component={FinishScreen} path="/finish" exact />

      <Route component={SaveDetailsScreen} path="/save-details" exact />

      <Redirect from="*" to={urlCreator('/delivery')} />
    </Switch>
  )
}
