import {
  UpdateCustomerRequest,
  GetCheckCustomerRequest,
  GetCustomerRequest,
  Customer,
} from '@softcery/qc-apiclient/index'
import { createEffect, createEvent, createStore, combine, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { apiService, errorHandler } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { updateError } from '~/shared/lib'

// TODO: think about simplify this store (mb replace code info)
const customerInitialState = {
  id: '',
  tokenexToken: '',
  tokenexTokenHMAC: '',
  smsCode: '',
  codeUpdatedAt: '',
}

export const updateCustomerFx = createEffect(
  async (requestOptions: UpdateCustomerRequest) => {
    try {
      return await apiService.updateCustomer(requestOptions)
    } catch (res) {
      return errorHandler(res, 'Failed to save information')
    }
  },
)

export const getCheckCustomerFx = createEffect(
  async (requestOptions: GetCheckCustomerRequest) => {
    try {
      return await apiService.getCheckCustomer(requestOptions)
    } catch (res) {
      return errorHandler(res, `Failed to check customer`)
    }
  },
)

export const getCustomerFx = createEffect(async (requestOptions: GetCustomerRequest) => {
  try {
    return await apiService.getCustomer(requestOptions)
  } catch (res) {
    return errorHandler(res, `Failed to get information`)
  }
})

export const resetState = createEvent()

export const setTokenex =
  createEvent<{ tokenexToken: string; tokenexTokenHMAC: string }>()
export const setCustomerCode = createEvent<string>()
export const setCustomerExists = createEvent<boolean>()

export const $updateCustomerStatus = combine({
  loading: updateCustomerFx.pending,
  error: restore(updateCustomerFx.finally.map(updateError), ''),
})

export const $getCustomerStatus = combine({
  loading: getCustomerFx.pending,
  error: restore(getCustomerFx.finally.map(updateError), ''),
})

// TODO: think about need this store or rename it
export const $successfullyGetInformation = createStore(false)

export const $customerExists = createStore(false)

$successfullyGetInformation.on(getCustomerFx.done, () => true).reset(resetState)
$customerExists
  .on(setCustomerExists, (_, payload) => payload)
  .on(getCheckCustomerFx.done, () => true)
  .reset(resetState)

export const $customer = createStore<Partial<Customer>>(customerInitialState)

$customer
  .on(getCustomerFx.done, (state, { params, result }) => ({
    ...state,
    tokenexToken: result.customer?.tokenexToken || '',
    tokenexTokenHMAC: result.customer?.tokenexTokenHMAC || '',
    smsCode: params.code || '',
    codeUpdatedAt: Date.now(),
  }))
  .on(setTokenex, (state, payload) => ({
    ...state,
    tokenexToken: payload.tokenexToken,
    tokenexTokenHMAC: payload.tokenexTokenHMAC,
  }))
  .on(setCustomerCode, (state, payload) => ({
    ...state,
    smsCode: payload,
    codeUpdatedAt: Date.now(),
  }))
  .reset(resetState)

// sync with local storage
persist({ store: $customer, key: LOCAL_STORAGE_KEYS.Customer })
persist({ store: $customerExists, key: LOCAL_STORAGE_KEYS.CustomerExists })
persist({
  store: $successfullyGetInformation,
  key: LOCAL_STORAGE_KEYS.SuccessfullyGetInformation,
})
