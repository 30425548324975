import { GetCheckCustomerRequest, GetCustomerRequest } from '@softcery/qc-apiclient'
import { useStore, useStoreMap } from 'effector-react'
import { Form as FormikForm, FormikProps, FormikValues } from 'formik'
import debounce from 'lodash.debounce'
import mixpanel from 'mixpanel-browser'
import {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useWindowSize } from 'react-use'

import { saveDetailsModel } from '~/features/save-details'
import { AutocompleteInput } from '~/entities/autocomplete-input'
import { customerModel } from '~/entities/customer'
import { GoogleAutocompleteInput } from '~/entities/google-autocomplete-input'
import { PhoneInput } from '~/entities/phone-input'
import { sessionModel } from '~/entities/session'
import { BreakpointWidth } from '~/shared/styles'
import { Delivery, Option, PlaceDetails, PlaceOption } from '~/shared/types'
import {
  SubmitButton,
  Input,
  AddTitle,
  Error,
  CodeInput,
  CheckboxWithLabel,
} from '~/shared/ui'

import { addDeliveryInfoModel } from '..'
import {
  CANADA_PROVINCES,
  COUNTRIES,
  USA_STATES,
} from '../../../pages/delivery/constants'
import { styles } from './styles'

interface FormProps extends Omit<FormikProps<FormikValues>, 'setFieldValue'> {
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
  setFieldValue: (field: keyof Delivery, value: string, shouldValidate?: boolean) => void

  successfullySavedInformation: boolean
  acceptsSavingDetails: boolean
}

export function Form({
  submitCount,

  setFieldValue,
  setIsSubmitted,

  successfullySavedInformation,
  acceptsSavingDetails,
  ...props
}: FormProps): JSX.Element {
  const checkoutSettings = useStoreMap({
    store: sessionModel.$config,
    keys: [],
    fn: (config) => config.checkoutSettings,
  })
  const updateDeliveryStatus = useStore(addDeliveryInfoModel.$updateDeliveryStatus)
  const vendorId = useStore(sessionModel.$vendorId)
  const delivery = useStore(addDeliveryInfoModel.$delivery)
  const customer = useStore(customerModel.$customer)
  const customerExists = useStore(customerModel.$customerExists)
  const successfullyGetInformation = useStore(customerModel.$successfullyGetInformation)
  const getCustomerStatus = useStore(customerModel.$getCustomerStatus)

  const codeInputRef = useRef(null)

  // state for additional inputs
  const [showAddress2, setShowAddress2] = useState<boolean>(Boolean(delivery?.address2))
  const [showCompanyName, setShowCompanyName] = useState<boolean>(
    Boolean(delivery?.company),
  )

  // get window width
  const { width } = useWindowSize()
  // checks customer existing and if exist send code to phone
  const debouncedCheckCustomer = useCallback(
    debounce((args: GetCheckCustomerRequest) => {
      if (
        !customer.smsCode &&
        checkoutSettings?.savingDetailsEnableStatus === 'enabled'
      ) {
        customerModel.getCheckCustomerFx(args).then(() => {
          // TODO: remove it (mb replace to listener)
          saveDetailsModel.createSmsCodeFx({
            phone: args.phone || '',
            shop: vendorId,
          })

          // TODO: remove it (mb replace to listener)
          addDeliveryInfoModel.setDelivery({
            phone: args.phone,
            country: props.values.country,
          })
        })
      }
    }, 1000),
    [checkoutSettings, customer.smsCode, props.values.country],
  )

  // sends code and if code is valid prefill delivery
  const sendCode = (args: GetCustomerRequest) => {
    if (!customer.smsCode && checkoutSettings?.savingDetailsEnableStatus === 'enabled') {
      customerModel.getCustomerFx(args).then((payload) => {
        // prefill delivery
        const delivery = payload.customer?.delivery
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(delivery || {})) {
          if (props.values[key] !== value) {
            setFieldValue(key as keyof Delivery, (value as string) || '')
          }
        }

        // identify customer by his unique ID for mixpanel analytics
        mixpanel.identify(payload.customer?.id)

        mixpanel.people.set({
          customerName: `${delivery?.firstName} ${delivery?.lastName}`,
          customerEmail: delivery?.email,
          customerPhone: delivery?.phone,
        })

        mixpanel.track('Logged in')
      })
    }
  }

  // validate fields onBlur and onChange only when submit count > 0
  useEffect(() => {
    setIsSubmitted(Boolean(submitCount))
  }, [submitCount])

  // initialize places service
  const places = usePlacesService({
    apiKey: 'AIzaSyCEd6o7lGtUEEFJ0r4XuGeeP9UJ0Afj_2Q',
    options: { types: ['address'] },
    debounce: 500,
    language: 'en',
  })

  // get full address when address line 1 filled
  const getFullAddress = (
    placeId: string,
    setFieldValue: (
      field: keyof Delivery,
      value: string,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    places.placesService?.getDetails(
      { placeId, fields: ['address_components'] },
      (placeDetails: PlaceDetails) => {
        // [street_number?, route]
        const addressLine: string[] = []
        // [postal_code, postal_code_suffix?]
        const zip: string[] = []
        let city = ''
        placeDetails?.address_components.forEach((addressComponent) => {
          const types: string[] = [...addressComponent.types]

          switch (true) {
            // street number
            case types.includes('street_number'):
              return addressLine.push(addressComponent.long_name)
            // street name
            case types.includes('route'):
              return addressLine.push(addressComponent.long_name)
            // city
            case types.includes('locality'):
              city = addressComponent.long_name
              return undefined
            case types.includes('sublocality_level_1'):
              city = addressComponent.long_name
              return undefined
            // country
            case types.includes('country'):
              return setFieldValue('country', addressComponent.long_name)
            // state
            case types.includes('administrative_area_level_1'):
              setFieldValue('province', addressComponent.long_name)
              setFieldValue('provinceCode', '')
              // in some cases google autocomplete doesn't return address component with type 'locality'
              if (!city) {
                city = addressComponent.long_name
              }
              return undefined
            // zip code 5 digits
            case types.includes('postal_code'):
              return zip.push(addressComponent.long_name)
            // zip code suffix
            case types.includes('postal_code_suffix'):
              return zip.push(addressComponent.long_name)
            default:
              return undefined
          }
        })

        // google place api first return street_number then route, so we need to reverse first then join
        setFieldValue('city', city)
        setFieldValue('address1', addressLine.join(', '))
        setFieldValue('zip', zip.join('-'))
      },
    )
  }

  const provinceInput = useMemo(() => {
    switch (props.values.country) {
      case 'United States':
        return {
          label: 'Select State',
          options: USA_STATES,
        }
      case 'Canada':
        return {
          label: 'Select Province',
          options: CANADA_PROVINCES,
        }
      default:
        return {
          label: 'District',
          options: [],
        }
    }
  }, [props.values.country])

  const isCodeInputVisible =
    customerExists && !successfullyGetInformation && !customer.smsCode

  const codeInputFocus = () => {
    // @ts-expect-error
    codeInputRef.current?.textInput[0].focus()
  }

  useEffect(() => {
    if (isCodeInputVisible) {
      setTimeout(codeInputFocus, 100)
    }
  }, [customerExists])

  return (
    <FormikForm>
      <div
        css={[
          styles.pullInformation.container.base,
          isCodeInputVisible && styles.pullInformation.container.visible,
        ]}
      >
        <span
          css={styles.pullInformation.closeButton}
          onClick={() => customerModel.setCustomerExists(false)}
        >
          &#x2715;
        </span>

        <div css={styles.pullInformation.title}>Pull up your information</div>

        <div css={styles.pullInformation.infoTitle}>
          <span css={styles.pullInformation.enter}>Enter the code send to</span>{' '}
          <span css={styles.pullInformation.phone}>{props.values.phone}</span>
        </div>

        <CodeInput
          onCodeFill={(code) =>
            sendCode({
              code,
              phone: props.values.phone,
              shop: vendorId,
            })
          }
          isValid={getCustomerStatus?.error === ''}
          inputRef={codeInputRef}
        />

        {getCustomerStatus?.error && (
          <div css={styles.pullInformation.errorMessage}>{getCustomerStatus?.error}</div>
        )}
      </div>

      <div css={styles.container}>
        <Input name="email" label="Email address" />

        <PhoneInput
          onChange={(phone) => debouncedCheckCustomer({ phone, shop: vendorId })}
          name="phone"
          label="Phone Number"
          clientLoggedIn={!!customer.smsCode}
        />

        <Input name="firstName" label="First Name" />
        <Input name="lastName" label="Last Name" />

        <div css={styles.additionalField}>
          <AddTitle
            text="Add company name"
            onClick={() => setShowCompanyName((prev) => !prev)}
            dataTestId="addCompany_button"
          />
          {showCompanyName && <Input name="company" label="Company name" />}
        </div>

        <GoogleAutocompleteInput
          places={places}
          onOptionClick={(option: PlaceOption) => {
            getFullAddress(option.place_id, setFieldValue)
          }}
          name="address1"
          label="Address Line 1"
          dropdownDataTestId="address1_dropdown"
        />

        {/* tablets and desktop devices */}
        {width >= BreakpointWidth.MOBILE && (
          <AutocompleteInput
            name="country"
            label="Country"
            onOptionClick={(option: Option) => {
              if (option) {
                setFieldValue('country', option.name)
              }
            }}
            options={COUNTRIES}
            autocompleteDataTestId="country_dropdown"
          />
        )}

        <div css={styles.additionalField}>
          <AddTitle
            text="Add address line 2"
            onClick={() => setShowAddress2((prev) => !prev)}
            dataTestId="addAddress2_button"
          />

          {showAddress2 && <Input name="address2" label="Address Line 2" />}
        </div>

        <div css={styles.address}>
          {/* mobile devices */}
          {width <= BreakpointWidth.MOBILE && (
            <AutocompleteInput
              name="country"
              label="Country"
              onOptionClick={(option: Option) => {
                if (option) {
                  setFieldValue('country', option.name)
                }
              }}
              options={COUNTRIES}
            />
          )}

          <Input name="city" label="City" id="city_input" />

          <AutocompleteInput
            name="province"
            label={provinceInput.label}
            onOptionClick={(option: Option) => {
              if (option) {
                setFieldValue('province', option.name.split(',')[0])
                setFieldValue('provinceCode', option.code)
              }
            }}
            options={provinceInput.options}
            autocompleteDataTestId="province_dropdown"
          />

          <Input name="zip" label="Zip Code" />
        </div>

        {updateDeliveryStatus?.error && <Error>{updateDeliveryStatus?.error}</Error>}
      </div>

      {checkoutSettings?.savingDetailsEnableStatus === 'enabled' &&
        !successfullyGetInformation &&
        !successfullySavedInformation && (
          <CheckboxWithLabel
            setChecked={() =>
              saveDetailsModel.setAcceptsSavingDetails(!acceptsSavingDetails)
            }
            checked={acceptsSavingDetails}
            extendStyle={styles.checkbox}
            label="Save my details for a faster checkout"
          />
        )}

      <SubmitButton
        text="Continue to shipping method"
        // TODO: add shipping rate updating if have some bug
        loading={updateDeliveryStatus?.loading}
        dataTestId="delivery_submitButton"
      />
    </FormikForm>
  )
}
