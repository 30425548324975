import { UpdateDeliveryResponseBody } from '@softcery/qc-apiclient'
import { useStore } from 'effector-react'
import { Formik, FormikProps, FormikValues } from 'formik'
import mixpanel from 'mixpanel-browser'
import { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Form, addDeliveryInfoModel } from '~/features/add-delivery-info'
import { saveDetailsModel } from '~/features/save-details'
import { sessionModel } from '~/entities/session'
import { getGoogleAnalyticsV4 } from '~/shared/lib/analytics'
import { urlCreator } from '~/shared/lib/urlCreator'
import { Delivery } from '~/shared/types'

import { COUNTRIES } from './constants'
import { validationSchema } from './validation'

export function DeliveryScreen(): JSX.Element {
  const history = useHistory()

  const vendorId = useStore(sessionModel.$vendorId)
  const config = useStore(sessionModel.$config)
  const delivery = useStore(addDeliveryInfoModel.$delivery)
  const acceptsSavingDetails = useStore(saveDetailsModel.$acceptsSavingDetails)
  const successfullySavedInformation = useStore(
    saveDetailsModel.$successfullySavedInformation,
  )

  const formikRef = useRef<FormikProps<Delivery>>(null)

  // track event "Delivery section opened"
  useEffect(() => {
    // TODO: uncomment this code (not working)
    // mixpanel.track('Delivery section opened')
  }, [])

  // submit form
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  // TODO: simplify this function
  const onSubmit = (data: Delivery): void => {
    // TODO: remove it (mb replace to listener)
    addDeliveryInfoModel.setDelivery(data)

    // update delivery
    addDeliveryInfoModel
      .updateDeliveryFx({
        vendorId,
        checkout: {
          ...config?.checkout,
          delivery: {
            ...data,
            countryCode: COUNTRIES.filter((country) => country.name === data.country)[0]
              ?.code,
          },
          // need to clear previous selected shipping rate id
          selectedShippingRateId: '',
        },
      })
      // TODO: move to effector model
      // after delivery updating get shipping rates
      .then((response) => {
        // track event "Delivery section completed"
        mixpanel.track('Delivery section completed')

        // navigate to shipping
        history.push(urlCreator('/shipping'))

        const { checkout: newCheckout } = response as UpdateDeliveryResponseBody
        if (newCheckout) {
          getGoogleAnalyticsV4()?.addShippingInfo(newCheckout)

          mixpanel.register({
            customerName: `${newCheckout.delivery?.firstName} ${newCheckout.delivery?.lastName}`,
            customerEmail: newCheckout.delivery?.email,
            customerPhone: newCheckout.delivery?.phone,
          })
        }
      })
  }

  return (
    <Formik
      validateOnBlur={isSubmitted}
      validateOnChange={isSubmitted}
      initialValues={delivery}
      validationSchema={() => validationSchema(formikRef.current?.values.country || '')}
      onSubmit={(data) => onSubmit(data)}
      innerRef={formikRef}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form
          {...props}
          setIsSubmitted={setIsSubmitted}
          acceptsSavingDetails={acceptsSavingDetails}
          successfullySavedInformation={successfullySavedInformation}
        />
      )}
    </Formik>
  )
}
