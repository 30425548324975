import { Interpolation, Theme, useTheme } from '@emotion/react'
import { ButtonHTMLAttributes } from 'react'
import { arrowIcon } from '~/shared/assets/icons'
import { Button, Loader } from '..'
import { styles } from './styles'

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean
  text: string

  extendStyle?: {
    container?: Interpolation<Theme>
    button?: Interpolation<Theme>
  }
  disabled?: boolean
  showIcon?: boolean
  dataTestId?: string
}

export function SubmitButton({
  loading,
  text,
  extendStyle,
  disabled,
  showIcon = true,
  ...props
}: SubmitButtonProps): JSX.Element {
  const theme = useTheme()
  return (
    <div css={[styles.container, extendStyle?.container]}>
      <Button
        type="submit"
        {...props}
        extendStyle={[styles.base, extendStyle?.button]}
        loading={loading}
        disabled={disabled}
      >
        {loading && <Loader extendStyle={styles.loader} fill={theme.LIGHT_2} />}
        <span css={styles.text}>{text}</span>
        {showIcon && (
          <img css={loading && styles.contrastIcon} src={arrowIcon} alt="arrow" />
        )}
      </Button>
    </div>
  )
}
