import { useTheme } from '@emotion/react'
import { styles } from './styles'

interface ProgressBarProps {
  progress: number
}

// Common component that used on every screen
export function ProgressBar({ progress }: ProgressBarProps): JSX.Element {
  const theme = useTheme()

  return (
    <div css={styles.container}>
      <div css={styles.bar}>
        <div css={styles.progress(theme, progress)} />
      </div>
      <span data-testid="progress-value">{progress}%</span>
    </div>
  )
}
