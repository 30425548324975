import InnerHTML from 'dangerously-set-html-content'
import { useStore, useStoreMap } from 'effector-react'
import { useEffect, Fragment } from 'react'

import { Checkout } from '~/pages'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'

import { resetState } from './model'
import { withProviders } from './providers'

function App(): JSX.Element {
  const showCheckout = useStore(sessionModel.$showCheckout)

  const customer = useStore(customerModel.$customer)

  const scripts = useStoreMap({
    store: sessionModel.$config,
    keys: [],
    fn: (s) => s.checkoutSettings?.scripts,
  })

  // clear customer data if auth code older than 30 days
  useEffect(() => {
    if (!showCheckout) {
      return
    }

    // get 30 days ago date
    const date = new Date()
    date.setDate(date.getDate() - 30)

    // TODO: remove "@ts-expect-error"
    // reset all user data if 30 days or more have pressed since the code verification
    // @ts-expect-error
    if (customer.codeUpdatedAt && customer.codeUpdatedAt <= date.getTime()) {
      resetState()
    }
  }, [showCheckout])

  return (
    <Fragment>
      {scripts !== '' && <InnerHTML html={scripts || ''} />}

      <Checkout />
    </Fragment>
  )
}

export default withProviders(App)
