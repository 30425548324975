import querystring from 'querystring'
import { Theme } from '@emotion/react'
import { CreateCheckoutResponseBody, Checkout } from '@softcery/qc-apiclient'
import { createEffect, createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/local'
import { makeTheme } from '~/app/styles'
import { apiService } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { CreateCheckoutPayload } from './types'

export const setOrderStatusUrl = createEvent<string>()
export const setCheckout = createEvent<Checkout>()

export const createCheckoutFx = createEffect(
  async ({ vendorId, origin, items }: CreateCheckoutPayload) => {
    try {
      return await apiService.createCheckout({
        vendorId,
        origin,
        fields: { lineItems: JSON.parse(items) },
      })
    } catch (error) {
      window.parent?.postMessage(JSON.stringify({ type: 'error' }), '*')
      return {}
    }
  },
)

export const $config = createStore<CreateCheckoutResponseBody>({})
export const $showCheckout = createStore(false)
export const $theme = createStore<Theme | null>(null)
export const $orderStatusUrl = createStore('')
export const $vendorId = createStore(
  querystring.parse(window.location.search.slice(1)).vendorId as string,
)

$theme.on(createCheckoutFx.doneData, (_, theme) =>
  makeTheme((theme as CreateCheckoutResponseBody).checkoutStyles || {}),
)

$showCheckout.on(createCheckoutFx.done, () => true)

$orderStatusUrl.on(setOrderStatusUrl, (_, orderStatusUrl) => orderStatusUrl)

$config
  .on(createCheckoutFx.doneData, (_, config) => config)
  .on(setCheckout, (state, checkout) => ({
    ...state,
    checkout,
  }))

// sync with local storage
persist({ store: $config, key: LOCAL_STORAGE_KEYS.Config })
persist({ store: $theme, key: LOCAL_STORAGE_KEYS.Theme })
persist({ store: $showCheckout, key: LOCAL_STORAGE_KEYS.ShowCheckout })
persist({ store: $vendorId, key: LOCAL_STORAGE_KEYS.VendorId })
persist({ store: $orderStatusUrl, key: LOCAL_STORAGE_KEYS.OrderStatusUrl })
