import { ShippingRate } from '@softcery/qc-apiclient'
import {
  createEvent,
  createStore,
  createEffect,
  combine,
  restore,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import mixpanel from 'mixpanel-browser'

import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { apiService, errorHandler } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/shared/config'
import { browserHistory, updateError, urlCreator } from '~/shared/lib'
import { UpdateShippingRatePayload, Shipping } from './types'

export const resetState = createEvent()
export const updateShippingRates = createEvent<ShippingRate[]>()

export const updateShippingRateFx = createEffect(
  async ({ vendorId, checkout }: UpdateShippingRatePayload) => {
    try {
      return await apiService.updateShippingRate({
        vendorId,
        fields: { checkout },
      })
    } catch (res) {
      return errorHandler(res, 'Failed to update shipping rate')
    }
  },
)

export const $updateShippingRateStatus = combine({
  loading: updateShippingRateFx.pending,
  error: restore(updateShippingRateFx.finally.map(updateError), ''),
})

export const $shipping = createStore<Shipping>({
  shippingRates: [],
  selectedShippingRateId: '',
})

$shipping
  .on(customerModel.getCustomerFx.doneData, (state, { customer }) => ({
    ...state,
    selectedShippingRateId: customer?.selectedShippingRateId || '',
  }))
  .on(updateShippingRateFx.doneData, (state, { checkout }) => ({
    ...state,
    selectedShippingRateId: checkout?.selectedShippingRateId || '',
  }))
  .on(updateShippingRates, (state, shippingRates) => ({
    ...state,
    shippingRates,
  }))
  .reset(resetState)

updateShippingRateFx.done.watch(() => {
  // track event "Shipping section completed"
  mixpanel.track('Shipping section completed')

  // navigate to Payment screen
  browserHistory.push(urlCreator('/payment'))
})

sample({
  clock: updateShippingRateFx.doneData,
  fn: ({ checkout }) => checkout!,
  target: sessionModel.setCheckout,
})

// sync with local storage
persist({ store: $shipping, key: LOCAL_STORAGE_KEYS.Shipping })
