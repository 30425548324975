import { ShippingRate } from '@softcery/qc-apiclient'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useStore } from 'effector-react'
import { Form as FormikForm, Field, FieldProps, FormikValues, FormikProps } from 'formik'
import { Dispatch, useEffect, SetStateAction } from 'react'

import { sessionModel } from '~/entities/session'
import { SubmitButton, Error } from '~/shared/ui'

import { addShippingInfoModel } from '..'
import { styles } from './styles'

interface FormProps extends FormikProps<FormikValues> {
  isFieldError: boolean
  setIsFieldError: Dispatch<SetStateAction<boolean>>
}

export function Form({
  initialValues: { selectedShippingRateId },
  errors,
  setIsFieldError,
  isFieldError,
}: FormProps): JSX.Element {
  const { loading, error } = useStore(addShippingInfoModel.$updateShippingRateStatus)
  const config = useStore(sessionModel.$config)

  const currency = getSymbolFromCurrency(config?.checkout?.currency || '')

  const getPrice = (optionTitle: string, optionPrice: string) => {
    // local pickup
    if (
      optionTitle.toLowerCase() ===
      config?.checkoutSettings?.vendorLocalPickupRateTitle?.toLowerCase()
    ) {
      return config?.checkoutSettings?.localPickupRateDescription || ''
    }
    // when rate price equal to zero
    if (parseFloat(optionPrice || '').toFixed(2) === '0.00') {
      return 'FREE'
    }

    return currency + parseFloat(optionPrice).toFixed(2)
  }

  // set isFieldError when errors object changed
  useEffect(() => {
    setIsFieldError(Boolean(errors.selectedShippingRateId))
  }, [errors.selectedShippingRateId])

  let ratesWithLocalPickup: ShippingRate[] = []
  // if has local pickup rate reorder to make it last one
  if (config?.checkoutSettings?.vendorLocalPickupRateTitle) {
    ratesWithLocalPickup = [...(config?.checkout?.shippingRates || [])]
    const indexOfLocalRate = ratesWithLocalPickup.findIndex(
      (rate) => rate.title === config?.checkoutSettings?.vendorLocalPickupRateTitle,
    )
    ratesWithLocalPickup?.push(...ratesWithLocalPickup.splice(indexOfLocalRate!, 1))
  }

  return (
    <FormikForm>
      <div data-testid="shipping-rates">
        {(ratesWithLocalPickup.length > 0
          ? ratesWithLocalPickup
          : config?.checkout?.shippingRates
        )?.map((option) => (
          <Field key={option.vendorId} name="selectedShippingRateId">
            {({ field }: FieldProps<FormikValues>) => (
              <label css={styles.label.container} htmlFor={option.vendorId}>
                <input
                  {...field}
                  css={styles.radio}
                  id={option.vendorId}
                  // initial value for previous selection
                  defaultChecked={selectedShippingRateId === option?.vendorId}
                  type="radio"
                  value={option.vendorId}
                />

                <label css={styles.label.text} htmlFor={option.vendorId}>
                  <div css={styles.labelTitle}>{option.title}</div>
                  <div css={styles.labelPrice}>
                    {getPrice(option.title || '', option.price || '')}
                  </div>
                </label>
              </label>
            )}
          </Field>
        ))}

        <div
          css={[
            styles.fieldErrorMessage.base,
            isFieldError && styles.fieldErrorMessage.visible,
          ]}
          id="shipping_field-error"
          data-testid="shipping_field-error"
        >
          Please select your shipping method
        </div>
      </div>

      {error && <Error>{error}</Error>}

      <SubmitButton
        text="Continue to Payment"
        loading={loading}
        dataTestId="shipping_submitButton"
        disabled={!selectedShippingRateId}
      />
    </FormikForm>
  )
}
