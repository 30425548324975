import qs from 'querystring'
import { useStore } from 'effector-react'
import { Fragment, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { sessionModel } from '~/entities/session'
import { urlCreator } from '~/shared/lib'
import { BreakpointWidth } from '~/shared/styles'
import { Footer, Popup, Header, Sha, Loader } from '~/shared/ui'

import { Routing } from './routing'
import { styles } from './styles'

export function Checkout(): JSX.Element {
  const { pathname } = useLocation()
  const history = useHistory()

  const showCheckout = useStore(sessionModel.$showCheckout)
  const vendorId = useStore(sessionModel.$vendorId)
  const loading = useStore(addDeliveryInfoModel.$commonLoading)

  // get vendorId, origin and items from query string
  const { origin, items, platform } = qs.parse(window.location.search.slice(1))

  const { width } = useWindowSize()

  // state for cart component showing
  const [showCart, setShowCart] = useState<boolean>(width > BreakpointWidth.TABLET)

  // TODO: Move to sample with useGate
  useEffect(() => {
    // creating checkout in Quick Checkout Engine
    sessionModel
      .createCheckoutFx({
        vendorId: vendorId as string,
        origin: origin as string,
        items: items as string,
      })
      // TODO: move to effector model
      .then(() => {
        history.push(urlCreator('/delivery'))
      })
  }, [])

  if (!showCheckout) {
    return (
      <div data-testid="loader" css={styles.container(platform as string)}>
        <Loader extendStyle={styles.loader} fill="rgba(0, 0, 0, 0.35)" />
      </div>
    )
  }

  return (
    <Fragment>
      <Popup
        showCart={showCart}
        loading={loading}
        // redirect to order url for closing popup
        onClose={() => window.parent?.postMessage(JSON.stringify({ type: 'close' }), '*')}
      >
        <Header showCart={showCart} loading={loading} setShowCart={setShowCart} />

        <Routing />

        {pathname !== '/save-details' && <Footer />}
      </Popup>

      <Sha />
    </Fragment>
  )
}
