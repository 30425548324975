import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { addPaymentInfoModel } from '~/features/add-payment-info'
import { addShippingInfoModel } from '~/features/add-shipping-info'
import { saveDetailsModel } from '~/features/save-details'
import { customerModel } from '~/entities/customer'
import { sessionModel } from '~/entities/session'
import { okhandIcon } from '~/shared/assets/icons'
import { urlCreator } from '~/shared/lib'
import { SubmitButton, Error } from '~/shared/ui'

import { styles } from './styles'

export function FinishScreen(): JSX.Element {
  const history = useHistory()

  const orderStatusUrl = useStore(sessionModel.$orderStatusUrl)
  const vendorId = useStore(sessionModel.$vendorId)

  const customer = useStore(customerModel.$customer)
  const delivery = useStore(addDeliveryInfoModel.$delivery)
  const shipping = useStore(addShippingInfoModel.$shipping)
  const payment = useStore(addPaymentInfoModel.$payment)

  const { loading: creatingSmsCode, error: createSmsCodeError } = useStore(
    saveDetailsModel.$createSmsCodeStatus,
  )

  const savingDetailsEnableStatus = useStoreMap({
    store: sessionModel.$config,
    keys: [],
    fn: ({ checkoutSettings }) => checkoutSettings?.savingDetailsEnableStatus,
  })

  const onUpdateDetails = () => {
    // TODO: move to effector model
    if (!customer.smsCode) {
      history.push(urlCreator('/save-details'))
      return
    }

    customerModel
      .updateCustomerFx({
        phone: delivery.phone || '',
        shop: vendorId,
        code: customer.smsCode,
        fields: {
          delivery,
          selectedShippingRateId: shipping.selectedShippingRateId,
          ccName: payment.ccName,
          ccExpMonth: payment.ccExpMonth,
          ccExpYear: payment.ccExpYear,
          ccFirstSix: payment.ccFirstSix,
          ccLastFour: payment.ccLastFour,
          tokenexToken: customer.tokenexToken,
          tokenexTokenHMAC: customer.tokenexTokenHMAC,
        },
      })
      // TODO: move to effector model
      .then(() => {
        history.push(urlCreator('/save-details'))
      })
  }

  const closeCheckoutTimeout = useMemo(
    () =>
      setTimeout(() => {
        console.log('sending message to close checkout', { orderStatusUrl })

        // TODO: think about it
        // // set items in ls to initial state
        // localStorage.setItem(
        //   'quick_checkout_checkout.meta',
        //   JSON.stringify(checkoutInitialState.meta),
        // )
        // localStorage.setItem(
        //   'quick_checkout_checkout.checkout',
        //   JSON.stringify(checkoutInitialState.checkout),
        // )

        window.parent?.postMessage(
          JSON.stringify({ type: 'navigate', href: orderStatusUrl }),
          '*',
        )
      }, 5000),
    [],
  )

  // redirect to order page after timeout
  useEffect(() => {
    // if clicked save details cancel close checkout timeout
    if (creatingSmsCode) {
      clearTimeout(closeCheckoutTimeout)
    }

    return () => clearTimeout(closeCheckoutTimeout)
  }, [creatingSmsCode])

  return (
    <div css={styles.container}>
      <img src={okhandIcon} alt="Ok hand" data-testid="finish-ok_hand" />

      <div css={styles.title}>Your order is complete!</div>

      {!creatingSmsCode && (
        <div css={styles.infoTitle}>
          You will be redirected back to the store in 5 seconds...
        </div>
      )}

      {createSmsCodeError && <Error>{createSmsCodeError}</Error>}

      {savingDetailsEnableStatus === 'enabled' && (
        <SubmitButton
          text="Update my details for a faster checkout"
          loading={creatingSmsCode}
          extendStyle={{ button: styles.button }}
          showIcon={false}
          onClick={onUpdateDetails}
        />
      )}
    </div>
  )
}
