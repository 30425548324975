import { createEvent, sample } from 'effector'
import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { addPaymentInfoModel } from '~/features/add-payment-info'
import { addShippingInfoModel } from '~/features/add-shipping-info'
import { saveDetailsModel } from '~/features/save-details'
import { customerModel } from '~/entities/customer'

export const resetState = createEvent()

sample({
  clock: resetState,
  target: [
    customerModel.resetState,
    addDeliveryInfoModel.resetState,
    addShippingInfoModel.resetState,
    addPaymentInfoModel.resetState,
    saveDetailsModel.resetState,
  ],
})
