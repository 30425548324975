import { useStoreMap, useStore } from 'effector-react'
import { Formik, FormikProps, FormikValues } from 'formik'
import mixpanel from 'mixpanel-browser'
import { Fragment, useState, useEffect } from 'react'

import { addShippingInfoModel, Form } from '~/features/add-shipping-info'
import { sessionModel } from '~/entities/session'

import { styles } from './styles'
import { validationSchema } from './validation'

export function ShippingScreen(): JSX.Element {
  const checkout = useStoreMap({
    store: sessionModel.$config,
    keys: [],
    fn: (config) => config.checkout,
  })
  const shipping = useStore(addShippingInfoModel.$shipping)
  const vendorId = useStore(sessionModel.$vendorId)

  const selectedShippingRateId = (
    shipping?.selectedShippingRateId !== ''
      ? shipping?.selectedShippingRateId
      : shipping.shippingRates[0]?.vendorId || ''
  ) as string

  const [isFieldError, setIsFieldError] = useState<boolean>(false)

  // track event "Shipping section opened"
  useEffect(() => {
    mixpanel.track('Shipping section opened')
  }, [])

  // submit form
  const onSubmit = ({
    selectedShippingRateId,
  }: {
    selectedShippingRateId: string
  }): void => {
    // update shipping rate
    addShippingInfoModel.updateShippingRateFx({
      vendorId,
      checkout: { ...checkout, selectedShippingRateId },
    })
  }

  return (
    <Fragment>
      <h2 css={[styles.title.base, isFieldError && styles.title.error]}>
        Choose your shipping method:
      </h2>
      <Formik
        initialValues={{ selectedShippingRateId }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<FormikValues>) => (
          <Form
            {...props}
            isFieldError={isFieldError}
            setIsFieldError={setIsFieldError}
          />
        )}
      </Formik>
    </Fragment>
  )
}
