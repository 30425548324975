import valid from 'card-validator'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useStore } from 'effector-react'
import { Field, FieldProps, Form as FormikForm, FormikProps, FormikValues } from 'formik'
import mixpanel from 'mixpanel-browser'
import { useEffect, useRef, RefObject, Fragment, useState } from 'react'

import { sessionModel } from '~/entities/session'
import { TokenexInput } from '~/entities/tokenex-input'
import { editIcon, lockLgIcon } from '~/shared/assets/icons'
import { Input, SubmitButton, Error, AddTitle, CheckboxWithLabel } from '~/shared/ui'

import { addPaymentInfoModel } from '..'
import { formatExpirationDate } from '../lib'
import { TokenexIframes } from '../types'
import { styles } from './styles'

interface FormProps extends FormikProps<FormikValues> {
  tokenexIframeRef: RefObject<any>
  iframes: TokenexIframes

  cardIcon: string
  cardType: string
  resetCardType: () => void
  isSavedPaymentDetails: boolean
  setSavedPaymentDetails: React.Dispatch<React.SetStateAction<boolean>>

  setIsFormikValidRef: (isValid: boolean) => void
}

export function Form({
  values,
  setFieldValue,
  setFieldError,
  isValid,
  isSavedPaymentDetails,
  setSavedPaymentDetails,
  resetCardType,

  tokenexIframeRef,
  cardIcon,
  cardType,
  iframes,

  setIsFormikValidRef,
}: FormProps): JSX.Element {
  const vendorId = useStore(sessionModel.$vendorId)

  const { error } = useStore(addPaymentInfoModel.$completeCheckoutStatus)

  const loading = useStore(addPaymentInfoModel.$formSubmitting)

  const couponApplying = useStore(addPaymentInfoModel.$couponUpdating)
  const couponApplied = useStore(addPaymentInfoModel.$couponApplied)

  const payment = useStore(addPaymentInfoModel.$payment || {})

  // get config
  const config = useStore(sessionModel.$config)

  // agreement checkbox state
  const [agreement, setAgreement] = useState<boolean>(true)

  // state for coupon
  const [showCoupon, setShowCoupon] = useState<boolean>(Boolean(payment?.discountCode))

  // ref for auto focus
  const ccExpYearRef = useRef<HTMLInputElement>(null)

  const firstRender = useRef(true)

  // set formik isValid state, need for tokenization
  useEffect(() => {
    setIsFormikValidRef(isValid)
  }, [isValid])

  // focus to expiration year input if typed value is valid
  useEffect(() => {
    if (
      !firstRender.current &&
      valid.expirationMonth(values.ccExpMonth).isValid &&
      values.ccExpMonth?.length === 2
    ) {
      ccExpYearRef.current?.focus()
      // clear values on focus
      setFieldValue('ccExpYear', '', false)
    }
  }, [values.ccExpMonth])

  // focus to cvv input if typed value is valid
  useEffect(() => {
    if (!firstRender.current && valid.expirationYear(values.ccExpYear, 50).isValid) {
      tokenexIframeRef?.current?.cvvFocus()
    }
  }, [values.ccExpYear])

  // need this for not focusing inputs automatically
  useEffect(() => {
    firstRender.current = false
  }, [])

  // applyCoupon used sending coupon to api and apply discount for checkout
  const applyCoupon = (discountCode: string) => {
    return addPaymentInfoModel
      .updateDiscountFx({
        discountCode,
        vendorId,
        checkout: config?.checkout || {},
        // TODO: think about replace this function from arguments to samples or mb finally block
        setFieldError: (message: string) => setFieldError('discountCode', message),
      })
      .then(() => {
        mixpanel.register({
          couponApplied: true,
        })
      })
  }

  // deleteCoupon used for deleting discount coupon for checkout
  const deleteCoupon = () => {
    return addPaymentInfoModel
      .deleteDiscountFx({
        vendorId,
        checkout: config?.checkout || {},
        // TODO: think about replace these functions from arguments to samples or mb finally block
        setFieldError: (message: string) => setFieldError('discountCode', message),
        setFieldValue: (value: string) => setFieldValue('discountCode', value),
      })
      .then(() => {
        mixpanel.register({
          couponApplied: false,
        })
      })
  }

  return (
    <FormikForm>
      <div css={styles.header}>
        <div css={styles.title.container}>
          <img src={lockLgIcon} alt="lock" />
          <h2 css={styles.title.text}>Payment</h2>
        </div>

        {config?.checkoutSettings?.couponsEnableStatus === 'enabled' && (
          <AddTitle text="Add coupon code" onClick={() => setShowCoupon(!showCoupon)} />
        )}
      </div>
      <div css={styles.formFields}>
        {showCoupon && config?.checkoutSettings?.couponsEnableStatus === 'enabled' && (
          <div css={styles.coupon.container}>
            <Input
              success={couponApplied}
              readOnly={couponApplied}
              name="discountCode"
              label="Coupon"
            />

            <SubmitButton
              onClick={
                couponApplied
                  ? () => deleteCoupon()
                  : () => {
                      if (values.discountCode === '') {
                        return setFieldError('discountCode', 'Field should not be empty')
                      }
                      return applyCoupon(values.discountCode)
                    }
              }
              extendStyle={{
                button: [styles.coupon.button.base],
                container: styles.coupon.buttonContainer,
              }}
              loading={couponApplying}
              type="button"
              dataTestId="applyCouponButton"
              text={couponApplied ? 'Remove coupon' : 'Apply'}
              showIcon={false}
            />
          </div>
        )}
        <div
          css={[
            styles.paymentContainer.base,
            !isSavedPaymentDetails && styles.paymentContainer.visible,
          ]}
        >
          <TokenexInput
            containerId="tokenexIframeCCNumberContainer"
            icon={<img css={styles.cardIcon} src={cardIcon} alt={cardType || 'card'} />}
            name="ccNumber"
            label="Card Number"
            onLabelClick={() => tokenexIframeRef?.current?.focus()}
            iframe={iframes.ccNumber}
          />

          <div css={styles.cardInfo}>
            <Input name="ccName" label="Name as it appears on card" />

            <Input
              onChangeCustom={(e) => {
                setFieldValue('ccExpMonth', formatExpirationDate(e), false)
                return e
              }}
              name="ccExpMonth"
              label="mm"
            />

            <Input
              ref={ccExpYearRef}
              onChangeCustom={(e) => {
                setFieldValue('ccExpYear', formatExpirationDate(e), false)
                return e
              }}
              name="ccExpYear"
              label="yy"
            />

            <TokenexInput
              containerId="tokenexIframeCCCvvContainer"
              name="ccCvv"
              label="CVV"
              onLabelClick={() => tokenexIframeRef?.current?.cvvFocus()}
              iframe={iframes.ccCvv}
            />
          </div>
        </div>
        <div css={[styles.savedCard.container, !isSavedPaymentDetails && styles.dNone]}>
          <label css={styles.savedCard.label}>Saved card</label>

          <img
            alt="edit-card"
            css={styles.savedCard.editButton}
            src={editIcon}
            onClick={() => {
              setFieldValue('ccName', '', false)
              setFieldValue('ccExpMonth', '', false)
              setFieldValue('ccExpYear', '', false)
              resetCardType()
              setSavedPaymentDetails(false)
            }}
          />

          <div css={styles.savedCard.card.container}>
            <img
              css={styles.savedCard.card.icon}
              src={cardIcon}
              alt={cardType || 'card'}
            />
            <div>**** **** **** {payment?.ccLastFour}</div>
          </div>

          <div css={styles.savedCard.card.holder}>{payment?.ccName}</div>

          <div css={styles.savedCard.card.expires}>
            Expires {payment?.ccExpMonth}/{payment?.ccExpYear}
          </div>
        </div>
      </div>

      <div css={styles.checkboxesWrapper}>
        <CheckboxWithLabel
          setChecked={() => setAgreement((prev) => !prev)}
          checked={agreement}
          dataTestId="agreement-checkbox"
          label={
            <Fragment>
              Agree to{' '}
              <a
                href={config?.checkoutSettings?.termsAndConditionsLink}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                terms and conditions{' '}
              </a>{' '}
              and
              <a
                href={config?.checkoutSettings?.privatePolicyLink}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                privacy policy{' '}
              </a>
            </Fragment>
          }
          extendStyle={styles.agreementCheckbox}
        />

        {config?.checkoutSettings?.marketingCheckboxVisibility === 'show' && (
          <Field name="acceptsMarketingNewsletter">
            {({ field }: FieldProps<FormikValues>) => (
              <CheckboxWithLabel
                setChecked={() => {
                  setFieldValue('acceptsMarketingNewsletter', !field.value, false)
                }}
                checked={Boolean(field.value)}
                label={config?.checkoutSettings?.marketingCheckboxText || ''}
                extendStyle={styles.marketingCheckbox}
                dataTestId="marketing-checkbox"
              />
            )}
          </Field>
        )}
      </div>

      {error && <Error>{error}</Error>}

      {agreement && (
        <SubmitButton
          loading={loading}
          disabled={couponApplying}
          text={`Pay ${getSymbolFromCurrency(
            config?.checkout?.currency || '',
          )}${parseFloat(config?.checkout?.totalPrice || '').toFixed(2)} Securely`}
          extendStyle={{ button: styles.button }}
          dataTestId="payment_submitButton"
          onClick={() => tokenexIframeRef?.current?.validate()}
        />
      )}
    </FormikForm>
  )
}
