import ReactDOM from 'react-dom'
// TODO: add optional import (only in dev mode)
import 'effector-logger/inspector'

import App from './app'
import { initializeSentry } from './shared/lib'

initializeSentry()

ReactDOM.render(<App />, document.getElementById('root'))

// TODO: think about replace "then(some analytic functions calls)" constructions to effector samples
