import { useLocation } from 'react-router-dom'
// import { lockSmIcon } from '~/shared/assets/icons'
import { ProgressBar } from '..'
import { styles } from './styles'

// Common component that used on every screen
export function Footer(): JSX.Element {
  const { pathname } = useLocation()
  // progress value changes due to screen
  const renderSwitch = () => {
    switch (pathname) {
      case '/delivery':
        return 30
      case '/shipping':
        return 80
      case '/payment':
        return 98
      default:
        return 100
    }
  }

  return (
    <div css={styles.container}>
      <ProgressBar progress={renderSwitch()} />
      {/* <div css={styles.secured}>
        <img src={lockSmIcon} alt="lock" />
        <span>Secured by Eye4Fraud</span>/
      </div> */}
    </div>
  )
}
