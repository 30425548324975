import { SerializedStyles } from '@emotion/react'
import { HTMLAttributes } from 'react'
import { checkedIcon } from '../../assets/icons'
import { styles } from './styles'

interface CheckboxWithLabelProps extends HTMLAttributes<HTMLDivElement> {
  dataTestId?: string

  label: JSX.Element | string
  extendStyle?: SerializedStyles

  checked: boolean
  setChecked: () => void
}

export function CheckboxWithLabel({
  dataTestId,

  label,
  extendStyle,

  checked,
  setChecked,

  ...props
}: CheckboxWithLabelProps): JSX.Element {
  return (
    <div {...props} css={[styles.container, extendStyle]} data-testid={dataTestId}>
      <div
        onClick={setChecked}
        css={[styles.checkbox.base, checked && styles.checkbox.checked]}
      >
        {checked && <img src={checkedIcon} alt="checked" css={styles.icon} />}
      </div>

      <label css={styles.label}>{label}</label>
    </div>
  )
}
