export enum LOCAL_STORAGE_KEYS {
  // config
  Config = '@qc/config',
  Theme = '@qc/theme',
  ShowCheckout = '@qc/showCheckout',
  VendorId = '@qc/vendorId',
  OrderStatusUrl = '@qc/orderStatusUrl',

  // customer
  Customer = '@qc/customer',
  CustomerExists = '@qc/customerExists',
  SuccessfullyGetInformation = '@qc/successfullyGetInformation',

  // delivery
  Delivery = '@qc/delivery',

  // shipping
  Shipping = '@qc/shipping',

  // payment
  Payment = '@qc/payment',
  DiscountCode = '@qc/discountCode',
  AcceptsMarketingNewsletter = '@qc/acceptsMarketingNewsletter',

  // save details
  AcceptsSavingDetails = '@qc/acceptsSavingDetails',
  SuccessfullySavedInformation = '@qc/successfullySavedInformation',
}
