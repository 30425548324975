// TODO: move header from shared/ui

import getSymbolFromCurrency from 'currency-symbol-map'
import { useStore } from 'effector-react'
import { Fragment, Dispatch, SetStateAction } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { addDeliveryInfoModel } from '~/features/add-delivery-info'
import { sessionModel } from '~/entities/session'
import { urlCreator } from '~/shared/lib/urlCreator'

import { Button } from '..'
import { Step, Info, ShoppingCartIcon, ArrowLeftIcon } from './components'
import { styles } from './styles'

interface HeaderProps {
  showCart: boolean
  loading: boolean

  setShowCart: Dispatch<SetStateAction<boolean>>
}

// Common component that used on every screen
export function Header({ showCart, setShowCart, loading }: HeaderProps): JSX.Element {
  const history = useHistory()
  const { pathname } = useLocation()

  const config = useStore(sessionModel.$config)
  const delivery = useStore(addDeliveryInfoModel.$delivery)

  // get selected shipping rate
  const selectedShippingRate = config?.checkout?.shippingRates?.filter(
    (rate) => rate.vendorId === config?.checkout?.selectedShippingRateId,
  )[0]

  // get currency symbol
  const currencySymbol = getSymbolFromCurrency(config?.checkout?.currency || '')

  return (
    <div css={styles.container}>
      <div>
        <img css={styles.logo} src={config?.checkoutStyles?.logoUrl} alt="" />

        <Button
          dataTestId="cart-button"
          extendStyle={styles.button}
          onClick={() => setShowCart((prev) => !prev)}
        >
          <ShoppingCartIcon />

          <span>
            {config?.checkout?.totalPrice
              ? `${currencySymbol}${parseFloat(config?.checkout!.totalPrice).toFixed(2)}`
              : loading}
          </span>

          <ArrowLeftIcon rotateRight={showCart} />
        </Button>
      </div>

      <div css={styles.steps}>
        <Step
          linkTo={urlCreator('/delivery')}
          completed={!loading}
          accessible={
            !loading &&
            pathname !== '/delivery' &&
            pathname !== '/finish' &&
            pathname !== '/save-details'
          }
          id="delivery-step_title"
        >
          1. Delivery
        </Step>

        <Step
          linkTo={urlCreator('/shipping')}
          completed={!loading && Boolean(config?.checkout?.selectedShippingRateId)}
          accessible={!loading && pathname === '/payment'}
          id="shipping-step_title"
        >
          2. Shipping Method
        </Step>

        <Step
          linkTo={urlCreator('/payment')}
          completed={pathname.includes('/finish')}
          accessible={false}
          id="payment-step_title"
        >
          3. Payment
        </Step>
      </div>

      <div css={styles.info}>
        {pathname !== '/delivery' &&
          pathname !== '/finish' &&
          pathname !== '/save-details' && (
            <Fragment>
              <Info
                title="Your info"
                values={[
                  `${delivery?.firstName} ${delivery?.lastName}`,
                  `${delivery?.email}`,
                ]}
                // TODO: migrate to some Effector tool
                onEdit={() => history.push(urlCreator('/delivery'))}
                dataTestId="info_your-info"
                editId="edit_your-info"
              />

              <Info
                title="Shipping to"
                values={[
                  `${delivery?.address1}`,
                  `${delivery?.city} ${delivery?.provinceCode || delivery?.province}`,
                  `${delivery?.zip}`,
                  `${delivery?.country}`,
                ]}
                // TODO: migrate to some Effector tool
                onEdit={() => history.push(urlCreator('/delivery'))}
                dataTestId="info_shipping-to"
                editId="edit_shipping-to"
              />

              {pathname === '/payment' && (
                <Info
                  title={selectedShippingRate?.title || ''}
                  values={
                    config?.checkoutSettings?.vendorLocalPickupRateTitle?.toLowerCase() ===
                    selectedShippingRate?.title?.toLowerCase()
                      ? [config?.checkoutSettings?.localPickupRateDescription as string]
                      : [`${currencySymbol}${selectedShippingRate?.price}`]
                  }
                  // TODO: migrate to some Effector tool
                  onEdit={() => history.push(urlCreator(`/shipping`))}
                  dataTestId="info_shipping-rate"
                  bodyTestId="info_shipping-rate-body"
                  editId="edit_shipping-rate"
                />
              )}
            </Fragment>
          )}
      </div>
    </div>
  )
}
