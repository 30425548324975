import { Field, FieldProps, useFormikContext, FormikValues } from 'formik'
import ReactPhoneInput2 from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './styles.css'
import { addDeliveryInfoModel } from '~/features/add-delivery-info'

interface PhoneInputProps {
  name: string
  label: string
  clientLoggedIn: boolean
  onChange: (value: string) => void
}

export function PhoneInput({
  name,
  label,
  clientLoggedIn,
  onChange,
  ...props
}: PhoneInputProps): JSX.Element {
  const { values } = useFormikContext<FormikValues>()

  return (
    <Field {...props} name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps<FormikValues>) => (
        <div className="phone-input-container">
          <ReactPhoneInput2
            {...field}
            country="us"
            inputProps={{
              name,
              autoComplete: 'tel',
              'data-testid': `${name}_input`,
            }}
            inputClass={meta.error && 'form-control-error'}
            specialLabel={label}
            value={field.value as unknown as string}
            onChange={(value, country: { name: string }, event, formattedValue) => {
              setFieldValue(name, formattedValue, false)

              // TODO: think about move this logic outside component to onChange property
              // change country and province input
              if (!clientLoggedIn && country.name !== values.country) {
                // changing country input value
                setFieldValue('country', country.name, false)
                // clear province input value
                setFieldValue('province', '', false)
                // for sync with local storage
                addDeliveryInfoModel.setDelivery({ country: country.name, province: '' })
              }
              onChange(formattedValue)
            }}
          />
          <div className="error-message" id={`${name}_input-error`}>
            {meta.error}
          </div>
        </div>
      )}
    </Field>
  )
}
