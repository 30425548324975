import { Global } from '@emotion/react'
import { Fragment } from 'react'
import { global } from '../styles'

export const withStyles = (component: () => JSX.Element) => () =>
  (
    <Fragment>
      <Global styles={global} />

      {component()}
    </Fragment>
  )
